import { useBasketStore } from '~/stores/basket';

export function useBasketLines() {
    const runtimeConfig = useRuntimeConfig();
    const basketStore = useBasketStore();
    const lines = computed(() => {
        return basketStore.lines
            ? basketStore.lines.map((line) => {
                  let productUrl = '';
                  if (line?.productData?.html_url) {
                      productUrl += line?.productData?.html_url;
                      if (line?.productData.structure === 'child') {
                          productUrl += `?variant=${line?.productData?.id}`;
                      }
                  }
                  return {
                      id: line.id,
                      image: line?.productData?.images?.length
                          ? line.productData.images[0].src
                          : '',
                      name: line?.productData?.title || '',
                      info: line?.productData?.info,
                      // text: line.id,
                      price: line.price_incl_tax_excl_discounts,
                      quantity: line.quantity,
                      url: line.url,
                      max: line?.availability?.num_available || 99,
                      loading: line?.loading,
                      cut: line?.productData?.cut,
                      hide: line?.hide,
                      upc: line?.productData?.upc,
                      priceInvalid: line?.productData?.price?.action
                          ? parseFloat(
                                line?.productData?.price?.original_incl_tax *
                                    line.quantity,
                            ).toFixed(2)
                          : null,
                      offer: line?.productData?.price?.action,
                      htmlUrl: productUrl,
                      item_category: line?.productData?.item_category,
                      total_price_excl_tax: line?.price_excl_tax,
                      price_excl_tax: line?.productData?.price?.excl_tax,
                      // error: line.id,
                  };
              })
            : [];
    });
    return lines;
}
